<template>
  <div class="bg-beige-200 h-auto pb-10 pt-5">
    <div class="flex flex-col justify-center items-center my-4 uppercase lg:w-2/3
      w-11/12 mx-auto">
      <div class="flex flex-col lg:flex-row w-full">
        <div class="flex flex-col mt-8 mr-20 w-full">
            <label class="pb-2 text-left pl-2">
                {{$t('registration.harp')}}</label>
            <input
              class="w-full px-2 py-1 border-b-2 border-beige-500 text-black"
              :value="good.des_articolo"
              disabled
            >
        </div>
        <div class="flex flex-col mt-8 w-full">
            <label class="pb-2 text-left pl-2">
                {{$t('registration.finish')}}
            </label>
            <input
              class="w-full px-2 py-1 border-b-2 border-beige-500 text-black"
              :value="good.des_config"
              disabled
            >
        </div>
      </div>
      <div class="flex w-full flex-col lg:flex-row">
        <div class="flex flex-col mt-8 mr-20 w-full">
            <label class="pb-2 text-left pl-2">
                {{$t('registration.serial')}}</label>
            <input
              class="w-full px-2 py-1 border-b-2 border-beige-500 font-number text-black"
              :value="good.matricola"
              disabled
            >
        </div>
        <div class="flex flex-col mt-8 w-full">
            <label class="pb-2 text-left pl-2">
                {{$t('registration.date')}}
            </label>
            <litepie-datepicker
              as-single
              :formatter="formatter"
              :i18n="locale"
              :disable-date="disabledDates"
              v-model="sales_date"/>
        </div>
      </div>
      <div class="flex flex-col w-full lg:flex-row">
        <div class="mt-8 flex flex-col w-full border-b-2
        border-beige-500">
            <p class="pb-2 text-left pl-2">{{$t('registration.dealer')}}</p>
            <select class="p-2" v-model="dealer_id">
                <option v-for="dealer in dealers" :key="dealer.id"
                  :value='dealer.id'
                >
                  {{ dealer.company }}
                </option>
                <option value="other">
                    {{$t('registration.other')}}
                </option>
            </select>
        </div>
        <div class="flex mt-14 w-full ml-0 lg:ml-20" v-show="other">
            <material-input v-model="not_managed_dealer" :name="$t('registration.other_message')"
            class="w-full" :showMessage="showMessages">
            </material-input>
        </div>
      </div>
      <h1 class="text-beige-500 font-bold mt-20 mx-auto w-full
        text-center mb-6 border-b-2 border-beige-500 text-xl">
          {{$t('registration.customer')}}
      </h1>
      <div class="flex flex-col lg:flex-row w-full mt-6 lg:mt-10">
        <material-input v-model="customer.firstname" :showMessage="showMessages"
        :name="$t('registration.firstname')"
          class="mr-20 mb-10 lg:mb-6 w-full">
        </material-input>
        <material-input v-model="customer.lastname" :name="$t('registration.lastname')"
          class="w-full" :showMessage="showMessages">
        </material-input>
      </div>
      <div class="flex flex-col lg:flex-row w-full mt-10">
        <material-input v-model="customer.address1" :name="$t('registration.address')"
          class="mr-20 mb-10 lg:mb-6 w-full" :showMessage="showMessages">
        </material-input>
        <material-input v-model="customer.city" :name="$t('registration.city')"
          class="w-full mb-4 lg:mb-0" :showMessage="showMessages">
        </material-input>
      </div>
      <div class="flex flex-col lg:flex-row w-full mt-6 lg:mt-10">
        <material-input v-model="customer.state" :name="$t('registration.province')"
          class="mr-20 mb-10 lg:mb-6 w-full"
          :showMessage="(showMessages && customer.country==='it')">
        </material-input>
        <material-input v-model="customer.postcode" :name="$t('registration.postcode')"
        :showMessage="showMessages"
          class="w-full">
        </material-input>
      </div>
      <div class="flex flex-col lg:flex-row w-full mt-4">
        <div class="flex flex-col w-full">
            <p class="pb-2 text-left pl-2">
                {{$t('registration.nations')}}</p>
            <select
              class="p-2 border-b-2 border-beige-500"
              v-model="customer.country"
            >
                <option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.code"
                >
                  {{ country.name }}
                </option>
            </select>
            <span v-if="showMessages && !customer.country"
              class="italic text-colors-red-700 text-sm">
                {{ $t('input.fillField') }}
            </span>
        </div>
        <div class="flex flex-col w-full ml-0 lg:ml-20 mt-3 lg:mt-0"
            v-if="showTaxCode">
            <label class="pb-2 text-left pl-2">
                {{$t('registration.tax')}}
            </label>
            <input class="w-full px-2 py-1 border-b-2 border-beige-500"
            v-model="tax_number">
        </div>
      </div>
      <div class="flex flex-col lg:flex-row w-full mt-8 lg:mt-16">
        <material-input v-model="customer.phone" :name="$t('registration.phone')"
        class="mr-20 mb-6 w-full" :showMessage="showMessages">
        </material-input>
        <material-input v-model="customer.email" :name="$t('registration.mail')"
        class="w-full mt-3 lg:mt-0" :showMessage="showMessages">
        </material-input>
      </div>
      <div class="flex flex-col lg:flex-row w-full mt-10 justify-between">
        <div class="flex flex-col text-white lg:text-sm text-xs">
            <div class="flex items-center">
                <input type="checkbox" class="mr-3" v-model="termsOk">
                <label class="text-left" v-html="$t('registration.terms')">
                </label>
            </div>
            <div class="flex items-center mt-6 lg:mt-2">
                <input type="checkbox" class="mr-3" v-model="privacyOk">
                <label class="text-left" v-html="$t('registration.privacy')">
                </label>
            </div>
        </div>
        <button
          class="text-white uppercase font-bold bg-beige-500 rounded p-3 lg:px-3
          hover:scale-110 duration-300 transform w-1/2 lg:w-auto mt-10 lg:mt-0 mx-auto lg:mx-0"
          :class="isLoading ? 'loader' : ''"
          v-show="YouHaveToCheckAll"
          @click="submitRegistration"
        >
          {{$t('serial.confirm')}}
        </button>
      </div>
    </div>
    <div class="rounded-md bg-red-600 p-4 w-11/12 lg:w-3/4 mx-auto mt-4 lg:mt-10 shadow-lg"
            v-show="errors">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-white cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true" @click="errors = false">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1
                  1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10
                  11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1
                  1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-white font-number"
                    v-html="$t('registration.errors')"
                  >
                </h3>
              </div>
            </div>
          </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker';
import axios from 'axios';
import MaterialInput from '@/components/MaterialInput.vue';
import dayjs from 'dayjs';

export default {

  components: {
    MaterialInput,
    LitepieDatepicker,
  },

  setup() {
    const store = useStore();

    const locale = computed(() => store.getters.locale);
    const good = computed(() => store.getters.harp);
    const dealers = computed(() => store.getters.dealers);
    const countries = computed(() => store.getters.countries);
    const storedCustomer = computed(() => store.getters.customer);

    const addCustomer = (newCustomer) => store.dispatch('addCustomer', newCustomer);
    const addWarranty = (warranty) => store.dispatch('addWarranty', warranty);

    const formatter = ref({
      date: 'DD/MM/YYYY',
      month: 'MMM',
    });

    const disabledDates = (date) => date > new Date();

    return {
      locale,
      good,
      dealers,
      countries,
      storedCustomer,
      addCustomer,
      addWarranty,
      formatter,
      disabledDates,
    };
  },

  data() {
    return {
      customer: {
        firstname: '',
        lastname: '',
        address1: '',
        city: '',
        postcode: '',
        state: '',
        country: '',
        phone: '',
        email: '',
        lang: this.locale,
      },
      dealer_id: null,
      not_managed_dealer: '',
      sales_date: '',
      tax_number: '',
      registration: '',
      other: false,
      termsOk: false,
      privacyOk: false,
      isLoading: false,

      showMessages: false,
      errors: false,
    };
  },

  computed: {
    YouHaveToCheckAll() {
      return this.termsOk && this.privacyOk && this.sales_date && this.dealer_id !== null;
    },

    customerCountry() {
      return this.customer.country;
    },

    showTaxCode() {
      return this.customer.country === 'it';
    },
  },

  watch: {
    dealer_id() {
      if (this.dealer_id === 'other') {
        this.other = true;
        return;
      }
      this.other = false;
    },
  },

  methods: {
    openOther() {
      this.other = true;
    },

    checkInput() {
      let ok = true;
      Object.keys(this.customer).forEach((key) => {
        if (!this.customer[key]) {
          if (this.customer.country !== 'it' && key === 'state') {
            return;
          }
          ok = false;
        }
      });
      return ok;
    },

    async submitRegistration() {
      this.showMessages = false;

      if (!this.checkInput()) {
        this.showMessages = true;
        return;
      }

      this.isLoading = true;

      this.customer.company = `${this.customer.firstname} ${this.customer.lastname}`;
      if (this.tax_number) {
        this.customer.tax_number = this.tax_number;
      }

      const salesDate = dayjs.utc(this.sales_date, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');

      try {
        const customer = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_AITEMS_URL}/warranty/customers`,
          data: this.customer,
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.VUE_APP_AITEMS_TOKEN}`,
          },
        });
        this.addCustomer(customer.data);
      } catch (err) {
        this.errors = true;
        console.log(err);
        this.isLoading = false;
      }

      let good;

      try {
        good = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_AITEMS_URL}/warranty/goods`,
          data: {
            serial_no: this.good.matricola,
            family: this.good.family,
            harp_model: this.good.cod_articolo,
            harp_description: this.good.des_articolo,
            harp_config: this.good.cod_config,
            harp_config_description: this.good.des_config,
            dealer_id: this.dealer_id === 'other' ? null : this.dealer_id,
            dealer_name: this.not_managed_dealer,
            sales_date: salesDate,
            customer_id: this.storedCustomer.id,
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.VUE_APP_AITEMS_TOKEN}`,
          },
        });
      } catch (err) {
        this.errors = true;
        console.log(err);
        this.isLoading = false;
      }

      try {
        const warranty = await axios({
          method: 'get',
          url: `${process.env.VUE_APP_AITEMS_URL}/warranty/goods/${good.data.serial_no}`,
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.VUE_APP_AITEMS_TOKEN}`,
          },
        });
        this.addWarranty(warranty.data);
        this.$router.push('/summary');
      } catch (err) {
        this.errors = true;
        console.log(err);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.loader {
  color: transparent !important;
  pointer-events: none;
  position: relative;
}

.loader:after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  position: relative;
  position: absolute;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
  position: absolute !important;
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>
