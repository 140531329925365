<template>
  <div class="relative">
    <input
      class="block w-full border-b-2 border-beige-500 p-1 mt-2 px-2 outline-none
      bg-beige-academy-200 items-center"
      :type="type"
      :id="id ? id : nameToKebab"
      :name="nameToKebab"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value )"
      required
    >
    <label
      class="absolute"
      :for="id ? id : nameToKebab"
    >
      {{ name }}
    </label>
    <span v-if="show" class="italic text-colors-red-700 text-sm">
      {{ messageToShow }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {},
    name: {},
    modelValue: String,
    type: {
      default: 'text',
    },
    showMessage: Boolean,
    message: String,
  },

  data() {
    return {
      show: this.showMessage,
    };
  },

  watch: {
    modelValue() {
      this.show = false;
      this.$emit('clear');
    },

    showMessage() {
      if (this.message) {
        this.show = this.showMessage;
        return;
      }
      this.show = (this.showMessage && !this.modelValue);
    },
  },

  computed: {
    nameToKebab() {
      return this.toKebab(this.name);
    },
    messageToShow() {
      if (this.message) {
        return this.message;
      }
      return this.$t('input.fillField');
    },
  },

  methods: {
    toKebab(value) {
      return value.split(' ').join('-').toLowerCase();
    },
  },
};
</script>

<style scoped>
label {
  pointer-events:none;
  left: .5rem;
  top: 12px;
  bottom: 1rem;
  transition:0.2s ease all;
}

input {
    transition:0.2s ease all;
}

input:focus ~ label, input:valid ~ label {
  @apply font-normal;
  top: -24px;
}
</style>
